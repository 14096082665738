import React, { Component } from "react";
import selfpickup from '../../../assets/web/selfpickup.png'
import dinein from '../../../assets/web/dinein.png'
import market from '../../../assets/web/market.png'
import food from '../../../assets/web/food.png'
import { Link } from 'react-router-dom'
import DialogComponent from '../../common/modals/partySetComponent'
import { MERCHANT_TYPE } from "../../../constant";
import '../../../styles/menu_styles.css'

const isCluster = process.env.REACT_APP_IS_CLUSTER;
const now = new Date();
var currentMonth = ('0' + (now.getMonth() + 1)).slice(-2);
var currentDay = ('0' + (now.getDate())).slice(-2);
const formattedDate = now.getFullYear() + "-" + currentMonth + "-" + currentDay;
const currentHours = ('0' + now.getHours()).slice(-2);
const currentMinutes = ('0' + now.getMinutes()).slice(-2);
const formattedTime = currentHours + ":" + currentMinutes;

export default class home_page_user_layout extends Component{
  constructor(props) {
    super(props);
    this.state = {
      hawkername: window.location.pathname.split('/')[2].replaceAll('%20', ' '),
      display: false,
      show:0,
      timeout: false,
      seconds: process.env.REACT_APP_TIMEOUT,
      date:  formattedDate,
      time: "",
      isMarket: false
    }
    this.timer = 0;
    this.interval_start = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);

  }
  setDiningMode(dinemode, merchanttype) {
    console.log(dinemode)
    console.log(merchanttype)
    console.log(this.state.date)
    if (dinemode == 2) {
      this.setState({show:1})
      localStorage.setItem('collection_datetime',formattedDate + " " + formattedTime); // to be remove after demo (28/02/25)
    } else {
      this.setState({show:2})
      localStorage.setItem('collection_datetime',formattedDate + " " + formattedTime);
    }
    const merchantSession = localStorage.getItem('merchants');
    const isDownload = localStorage.getItem('isdownload');
    const orgid = localStorage.getItem('orgid');
    const projectname = localStorage.getItem('projectname');
    const loccode = localStorage.getItem('loccode');
    const hawkername = localStorage.getItem('hawkername');


    if(dinemode == 99){
      //popup
      localStorage.setItem('dinemode', dinemode);
      localStorage.setItem('orgid', orgid);
      localStorage.setItem('projectname', projectname);
      localStorage.setItem('loccode', loccode);
      localStorage.setItem('hawkername', hawkername);
      localStorage.setItem('merchanttype', merchanttype);
      
      this.setState({
        display:true
      })
    }else{
      localStorage.setItem('dinemode', dinemode);
      localStorage.setItem('orgid', orgid);
      localStorage.setItem('projectname', projectname);
      localStorage.setItem('loccode', loccode);
      localStorage.setItem('hawkername', hawkername);
      localStorage.setItem('merchanttype', merchanttype);

    }  
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let secondstime = this.state.seconds - 1000;
    // Check if we're at zero.
    if (secondstime == 0) { 
      clearInterval(this.timer);
      clearInterval();
      this.setState({
        timeout: true,
      });
    } else {
      this.setState({
        seconds: secondstime,
      });
    }
  }
  handleDate = (e) => {
    this.setState({
      date: e.target.value
    })
  }
  handleTime = (e) => {
    this.setState({
      time: e.target.value
    })
  }
  handleContinue = (e) => {
    var datetime = this.state.date + " " + this.state.time;
    console.log(datetime);
    // localStorage.setItem('collection_datetime',datetime);
  }
  scrollToComponent() {
    const component = document.getElementById("pickuptime");

    // Using smooth scroll for a better user experience
    component.scrollIntoView({ behavior: "smooth" });
}
  render() {
    const hawkerName = window.location.pathname.split('/')[2].replaceAll('%20', ' ')
    if (window.innerWidth > 481 && localStorage.getItem('hawkername') == null) {
      window.location.href = '/locations'
    } else {
      localStorage.setItem('hawkername', hawkerName)
    }
    
    clearInterval();
    this.startTimer();

    let linkurl;
    if (isCluster == 0 || isCluster == 1) {
      linkurl = "/merchants";
    } else {
      linkurl = "/merchants";
    }

    if (window.innerWidth < 481) {
      return (
        <div style={{ position: 'relative', width: '100%', height: '50vh' }}>
          <Link to={{ pathname: `/locations` }} style={{ position: 'absolute', top: "10px", left: '5%' }}>
            <button className="backWeb" style={{ width: '70px', height: '25px', fontSize: '10px' }} >Back</button>
          </Link>
          <h1 className="textdiningmode" style={{ fontSize: '18px', top: '15%', textDecoration: 'underline' }}>{localStorage.getItem('hawkername')}</h1>
          {localStorage.getItem('hawkername').includes('Market') && <>
          <h2 className="textdiningmode" style={{ fontSize: '14px', top: '24%' }}>Select Type</h2>
          <div className="container" style={{ padding: 0, top: '30%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
                <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => { this.setState({ isMarket: false }); this.setDiningMode(3,MERCHANT_TYPE.Market); }}>
                  <img src={market} alt="Market" style={{ width: '100%' }} />
                </div>
                <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => this.setState({isMarket:true,show:0})}>
                
                <img src={food} alt="Food" style={{ width: '100%' }} />
              </div>
             
            </div>
            {!this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} style={{fontSize: '14px',width:'35%'}}/>
                {/* <input type="date" name="date" placeholder="Enter Date" id="date" className="date" onChange={this.handleDate} /> */}
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} style={{fontSize: '14px',width:'35%'}}/>
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to={linkurl}>
                    <button className="continueWeb" style={{ fontSize: '10px'}}  onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>}
          </div>
          </>}
          {this.state.isMarket && <>
          <h2 className="textdiningmode" style={{ fontSize: '14px', top: '75%' }}>Select Options</h2>
          <div className="container" style={{ padding: 0, top: '85%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
              <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => this.setDiningMode(3,MERCHANT_TYPE.Food_Beverage)}>
                <img src={selfpickup} alt="Self Pick-up" style={{ width: '100%' }} />
              </div>
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => this.setDiningMode(4,MERCHANT_TYPE.Food_Beverage)}>
                  <img src={dinein} alt="Dine In" style={{ width: '100%' }} />
                </div>
                </Link>
                <div id="pickuptime" style={{ marginBottom: '30px' }}>
                  {/* <DialogComponent /> */}
                </div>
                
                
            </div>
            {this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} style={{fontSize: '14px',width:'35%'}}/>
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} style={{fontSize: '14px',width:'35%'}}/>
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px', marginBottom:'50px' }}>
                <Link to={linkurl}>
                  <button className="continueWeb" style={{ fontSize: '10px'}} onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>}
            
          </div>
        </>}
          {!this.state.hawkername.includes('Market') && <>
          <h2 className="textdiningmode" style={{ fontSize: '14px', top: '25%' }}>Select Options</h2>
          <div className="container" style={{ padding: 0, top: '35%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => this.setDiningMode(2,MERCHANT_TYPE.Market)}>
                  <img src={selfpickup} alt="Self Pick-up" style={{ width: '100%' }} />
                </div>
              </Link>
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '40%', display: 'inline-block' }} onClick={() => this.setDiningMode(4,MERCHANT_TYPE.Food_Beverage)}>
                  <img src={dinein} alt="Dine In" style={{ width: '100%' }} />
                </div>
              </Link>
              {/* <DialogComponent /> */}
            </div>
            {/* {!this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} style={{fontSize: '14px',width:'35%'}}/>
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} style={{fontSize: '14px',width:'35%'}}/>
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to={linkurl}>
                  <button className="continueWeb" style={{ fontSize: '10px'}} onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>} */}
           
          </div>
        </>}
        </div>
      );
    } else {
      return (
        <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
          <Link to={{ pathname: `/locations` }} style={{ position: 'absolute', top: "10px", left: '5%' }}>
            <button className="backWeb" >Back</button>
          </Link>
          <h1 className="textdiningmode" style={{ fontSize: '34px', top: '8%', textDecoration: 'underline' }}>{this.state.hawkername}</h1>
          {this.state.hawkername.includes('Market') && <>
          <h2 className="textdiningmode" style={{ fontSize: '20px', top: '15%' }}>Select Type</h2>
          <div className="container" style={{ padding: 0, top: '22%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
             
                <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => { this.setState({ isMarket: false }); this.setDiningMode(3,MERCHANT_TYPE.Market); }}>
                  <img src={market} alt="Market" style={{ width: '100%' }} />
                </div>
                <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => this.setState({isMarket:true,show:0})}>
                
                <img src={food} alt="Food" style={{ width: '100%' }} />
              </div>
              
            </div>
            {!this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} />
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} />
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to={linkurl}>
                  <button className="continueWeb" onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>}
            
            
          </div>
          </>}
          {this.state.isMarket && <>
          <h2 className="textdiningmode" style={{ fontSize: '20px', top: '65%' }}>Select Options</h2>
          <div className="container" style={{ padding: 0, top: '75%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
              <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => this.setDiningMode(3,MERCHANT_TYPE.Food_Beverage)}>
                <img src={selfpickup} alt="Self Pick-up" style={{ width: '100%' }} />
              </div>
              {/* </Link> */}
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => this.setDiningMode(4,MERCHANT_TYPE.Food_Beverage)}>
                  <img src={dinein} alt="Dine In" style={{ width: '100%' }} />
                </div>
                </Link>
                <div id="pickuptime" style={{ marginBottom: '50px' }}>
                  {/* <DialogComponent /> */}
                </div>
             
            </div>
            {this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} />
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} />
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px', marginBottom:'50px' }}>
                <Link to={linkurl}>
                  <button className="continueWeb" onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>}
            
          </div>
        </>}
          {!this.state.hawkername.includes('Market') && <>
          <h2 className="textdiningmode" style={{ fontSize: '20px', top: '15%' }}>Select Options</h2>
          <div className="container" style={{ padding: 0, top: '22%' }}>
            <div className="clearfix" style={{ textAlign: 'center' }}>
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => this.setDiningMode(2,MERCHANT_TYPE.Food_Beverage)}>
                  <img src={selfpickup} alt="Self Pick-up" style={{ width: '100%' }} />
                </div>
              </Link>
              <Link to={linkurl}>
                <div className="img-container-web" style={{ width: '18%', display: 'inline-block' }} onClick={() => this.setDiningMode(4,MERCHANT_TYPE.Food_Beverage)}>
                  <img src={dinein} alt="Dine In" style={{ width: '100%' }} />
                </div>
              </Link>
              {/* <DialogComponent /> */}
            </div>
            {/* {!this.state.isMarket && this.state.show == 1 && <>
              <h2 style={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', letterSpacing: '0.16', marginTop: '20px', marginBottom: '20px' }}>Select Date and Time</h2>
              <div className="clearfix" style={{ textAlign: 'center' }}>
                <input type="date" name="date" placeholder="Enter Date" id="date" className="date" disabled value={this.state.date} />
                <input type="time" name="time" placeholder="Enter Time" id="time" className="time" onChange={this.handleTime} />
              </div>
              <div className="clearfix" style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to={linkurl}>
                  <button className="continueWeb" onClick={(e) => this.handleContinue(e)}>Continue</button>
                </Link>
              </div>
            </>} */}
         
            
          </div>
        </>}
        </div>
          
        
      );
    }
  
  }
}